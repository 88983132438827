export const accountTextConfig = (content) => {
  return [
    {
      lable: '账户：',
      prop: 'account',
      value: ''
    },
    {
      lable: '开户行：',
      prop: 'bankName',
      value: ''
    },
    {
      lable: '账号：',
      prop: 'accountNo',
      value: ''
    }
  ]
}
