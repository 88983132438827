<template>
    <div class="left">
      <div class="newtitle">
        <div></div>
        <div>服务费账户</div>
        <div></div>
      </div>
      <div class="content" :style="{'background-color': addColor}">
        <div class="contentitem" v-for="item of accountTextConfig" :key="item.lable">
          <div class="redbox" v-if="isRedbox"></div>
          <div>{{ `${item.lable}${formatAccount(Index - 1)[item.prop] || ''}` }}</div>
        </div>
      </div>
    </div>
</template>

<script>
import { accountTextConfig } from '../utils/repayment-account.config'
export default {
  name: 'repayment-account',
  props: {
    // 账号数据
    bankAccount: {
      type: Array,
      default: () => []
    },
    // 索引
    Index: {
      type: Number,
      default: 1
    },
    // 是否打开小方块
    isRedbox: {
      type: Boolean,
      default: false
    },
    addColor: {
      type: String,
      default: '#f9f9f6'
    }
  },
  computed: {
    accountTextConfig () {
      return accountTextConfig(this)
    }
  },
  methods: {
    /**
     *账号处理
     * @param _index
     * @returns {*|{}}
     */
    formatAccount (_index) {
      if (this.bankAccount) {
        const accountInfo = this.bankAccount.find((item, index) => index === _index)
        return accountInfo || {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.accountabel {
  width: 100%;

  .content {
    padding: 14px 0 0 10px;
    width: 100%;
    margin-top: 11px;

    .contentitem {
      font-size: 14px;
      font-weight: 400;
      color: #38373a;
      display: flex;
      align-items: center;
      padding-bottom: 18px;
    }
  }

  .left {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .newtitle {
    display: flex;
    align-items: center;

    > div:nth-child(1) {
      width: 150px;
      height: 4px;
      background: linear-gradient(90deg, #ffffff,  #6996FF);
    }

    > div:nth-child(2) {
      font-size: 16px;
      font-weight: bold;
      color: #38373a;
      padding: 0 10px;
    }

    > div:nth-child(3) {
      width: 150px;
      height: 4px;
      background: linear-gradient(90deg, #6996FF,  #ffffff);
    }
  }
}
.redbox {
  width: 10px;
  height: 10px;
  background: #2862E7;
  margin-right: 8px;
}
</style>
